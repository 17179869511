import React, { useRef, useEffect, useState } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import { Tooltip } from '@junglescout/edna'

const Title = styled.p`
  width: 100%;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: ${props => props.numOfLines};
  -webkit-box-orient: vertical;
`

export const TruncatedTextWithTooltip = ({
  children,
  className,
  numOfLines,
  size,
  side
}) => {
  const contentRef = useRef()
  const [showTooltip, setShowTooltip] = useState(false)

  // If element scrollHeight is greater than clientHeight property
  // then we know the text is truncated so we use tooltip to display the full text
  useEffect(() => {
    if (contentRef.current) {
      const isEllipsisShowing =
        contentRef.current.scrollHeight > contentRef.current.clientHeight
      setShowTooltip(isEllipsisShowing)
    }
  }, [contentRef])

  const content = (
    <Title className={className} numOfLines={numOfLines} ref={contentRef}>
      {children}
    </Title>
  )

  if (!showTooltip) {
    return content
  }

  return (
    <Tooltip side={side} size={size} content={children}>
      {content}
    </Tooltip>
  )
}

TruncatedTextWithTooltip.defaultProps = {
  className: undefined,
  numOfLines: 2,
  size: 'large',
  side: 'right',
  children: ''
}

TruncatedTextWithTooltip.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  className: PropTypes.string,
  numOfLines: PropTypes.number,
  size: PropTypes.string,
  side: PropTypes.string
}
